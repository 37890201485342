/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import shirts from '../assets/image/image5.png';

function ProductItem() {
  return (
    <>
      <div className='flex flex-col w-full lg:w-full xl:w-full md:w-[249px] object-cover'>
        <Link to='#'>
          <div className='relative'>
            <img
              // w-[189px] h-[229px] md:w-[289px] lg:w-[289px] xl:w-[289px] md:h-[329px] lg:h-[329px] xl:h-[329px]
              className='w-full h-[229px] md:h-[358px] xl:h-[358px] lg:h-[358px] sm:h-[358px] object-cover'
              src={shirts}
              alt=''
            />
            <div className='absolute top-2 text-red-500 font-semibold md:px-5 md:py-1 lg:px-5 lg:py-1 sm:px-5 sm:py-1 xl:px-5 xl:py-1 py-1 px-3 text-sm bg-[#ffffff]'>
              -12%
            </div>
          </div>

          <div className='flex justify-between pt-2'>
            <span className='line-clamp-2 text-sm font-medium h-[42px]'>
              The Cloud Relaxed Cardigan Cloud Relaxed Cardigan Relaxed Cardigan
              Relaxed Cardigan
            </span>
          </div>
        </Link>
        <div className='h-[44px] flex justify-between items-center'>
          <div className='lg:text-sm md:text-sm xl:text-sm sm:text-sm text-[12px] font-medium'>
            Black
          </div>
          <div className='flex gap-1 text-[12px] md:text-[18px] lg:text-[18px] xl:text-[18px]'>
            <span className='line-through text-[#969696]'>
              120000<span>đ</span>
            </span>
            <span className='font-medium text-[#fe5c17]'>
              100000<span>đ</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductItem;
